<template>
  <div v-if="isAuthorized">
    <p style="font-weight:bold; color:#635F5D">Account Manager Performance Analytics</p>
    <div style="margin-top:50px;">
      <AccManagerPerformanceStats id="AccManagerPerformanceStats"></AccManagerPerformanceStats>
    </div>
  </div>
</template>
<script>
import AccManagerPerformanceStats from "../../components/AnalyticsCharts/AccManagerPerformanceStats/AccManagerPerformanceStats";
export default {
  components: { AccManagerPerformanceStats },
  data() {
    return {
      isAuthorized: false,
    };
  },
  created() {
    if (!localStorage.getItem("jwt")) {
      this.$router.push({ path: "/login" });
    } else {
      this.isAuthorized = true;
    }
  },
};
</script>
