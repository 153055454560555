<template>
  <div :id="computedChartContainerId" class="AccManagerPerformanceStatsChartContainer">
    <v-snackbar v-model="snackbar" :timeout="4000" top>
      {{ snackMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
   <div class="calenderContainerAccManager">
      <CalendarDialog
        v-on:callback="startDateCallback"
        :default-date="startDate"
        field-label="Starting Date"
        :controller="startDateController"
      ></CalendarDialog>
      <span>-</span>
      <CalendarDialog
        v-on:callback="endDateCallback"
        :default-date="endDate"
        field-label="Ending Date"
        :controller="endDateController"
      ></CalendarDialog>
      <v-select
        v-on:change="accManagerCallback"
        :items="amList"
        v-model="accManager"
        label="Account Manager"
      ></v-select>
      <div class="inputField">
        <v-checkbox
          @change="carStatusCallback"
          v-model="carStatus"
          label="Active Cars"
          value="active"
        ></v-checkbox>
        <v-checkbox
          @change="carStatusCallback"
          v-model="carStatus"
          label="Sold Cars"
          value="sold"
        ></v-checkbox>
      </div>
    </div>
    <svg 
      height="550" 
      width="1000" 
      :id="computedChartId"
      class="AccManagerPerformanceStatsChart"></svg>
  </div>
  
</template>
<script>
import * as d3 from "d3v4";
import getAxios from "../../../../services/axios-get";
import CalendarDialog from "../../CalendarDialog/CalendarDialog.vue";
export default {
  name: "AccManagerPerformanceStats",
  components: { CalendarDialog },
  props: {
    id: String,
    mode: String,
    lineColor: String,
    pointColor: String,
  },
  data() {
    return {
      chartId: "",
      snackbar: false,
      snackMessage: "",
      width: null,
      height: 350,
      margin: {
        top: 70,
        left: 100,
        right: 100,
        bottom: 100,
      },
      AccManagerPerformanceStatsData: null,
      AccManagerPerformanceStatsChartContainer: null,
      xAxisGroup: null,
      yAxisGroup: null,
      xScale: null,
      yScale: null,
      startDate: "2020-12-01",
      endDate: "2021-6-01",
      startDateController: {},
      endDateController: {},
      carStatus: ["active"],
      carStatusCategories: ["active","sold"],
      accManager: "Celeste",
      amList: [],
      amSearch: "",
      xAxisLegend: "Date",
      yAxisLegend: "Car Count",

    };
  },
  computed: {
    computedChartId: function() {
      return "AccManagerPerformanceStatsChart" + this.id;
    },
    computedChartContainerId: function() {
      return "AccManagerPerformanceStatsChartContainer" + this.id;
    },
  },
  methods: {
    // REQUIRE TO CHECK ITS PURPOSE
    showSnackBar(message) {
      this.snackMessage = message;
      this.snackbar = true;
      // console.log(this.snackMessage);
    },

    accManagerCallback() {
      this.getAccManagerPerformanceStats("update");
    },

    carStatusCallback() {
      if (this.carStatus.length == 0) {
        this.showSnackBar("Kindly select atleast one status!!");
        this.carStatus = ["active"];
      }
      this.getAccManagerPerformanceStats("update");
    },

    startDateCallback(startDate) {
      if (new Date(startDate) > new Date(this.endDate)) {
        this.showSnackBar("Start Date should be less than End Date!!");
        this.startDateController.updateDateInCalendar(this.startDate);
        return;
      }
      this.startDate = startDate;
      this.getAccManagerPerformanceStats("update");
    },

    endDateCallback(endDate) {
      if (new Date(endDate) < new Date(this.startDate)) {
        this.showSnackBar("End Date should be greater than Start Date!!");
        this.endDateController.updateDateInCalendar(this.endDate);
        return;
      }
      this.endDate = endDate;
      this.getAccManagerPerformanceStats("update");
    },

    fetchAMList() {
      const self = this;
      const params = {
        searchContent: self.amSearch,
      };
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/userSystem/accountManager`,
        params
      ).then(res => {
        if (res.data) {
          for (let ind = 1; ind < res.data.data.content.length; ind++) {
            self.amList.push(res.data.data.content[ind]['name']);
          }
        }
      });
    },

// INITIALISE GRAPH
    initGraph() {
      let chart = document.getElementById(this.computedChartContainerId);
      this.width = chart.clientWidth - 50;
      d3.select("#" + this.computedChartId).attr("width", this.width);
      let svg = d3
        .select("#" + this.computedChartId);

      this.accManagerPerformanceStatsChartContainer = svg
        .append("g")
        .attr(
          "transform",
          `translate(${this.margin.left}, ${this.margin.top})`
        );
      this.xAxisGroup = this.accManagerPerformanceStatsChartContainer.append("g");
      this.yAxisGroup = this.accManagerPerformanceStatsChartContainer.append("g");
      this.renderChart();
    },

// LOAD LEGEND
    loadLegend() {
      d3.select(`.x-axis-legend-AccManagerPerformanceStats-${this.id}`).remove();
      d3.select(`.y-axis-legend-AccManagerPerformanceStats-${this.id}`).remove();

    // LOAD LEGEND (X-AXIS)
      this.accManagerPerformanceStatsChartContainer
        .append("text")
        .attr(
          "class",
          `x-axis-legend x-axis-legend-AccManagerPerformanceStats-${this.id}`
        )
        .attr(
          "transform",
          `translate( ${(this.width - 120) / 2}, ${
            this.width < 800 ? this.height + 100 : this.height + 50
          })`
        )
        .text("Date");

    // LOAD LEGEND (Y-AXIS)
      this.accManagerPerformanceStatsChartContainer
        .append("text")
        .attr(
          "class",
          `y-axis-legend y-axis-legend-AccManagerPerformanceStats-${this.id}`
        )
        .attr("transform", `rotate(-90)`)
        .attr("x", -this.height / 2 - 50)
        .attr("y", -80)
        .text(this.yAxisLegend);
    },

// LOAD X-AXIS (ACCOUNT MANAGER) TO BE EDITED
    loadXAxis() {
      this.xScale = d3
        .scaleTime()
        .range([0, this.width - 120])
        .domain(
          d3.extent(this.AccManagerPerformanceStatsData, d => {
            return new Date(d["date"]);
          })
        )
        .nice();
      this.xAxisGroup
        .attr("class", `x-axis-car-count-line-chart-${this.id}`)
        .attr("transform", `translate(0,${this.yScale(0)})`)
        .call(
          d3
            .axisBottom(this.xScale)
            .tickSize(-this.height)
            .tickPadding([10])
        );
    },





// // LOAD Y-AXIS (CAR COUNT) TO BE EDITED
    loadYAxis() {
      let amountRange = d3.extent(this.AccManagerPerformanceStatsData, d => {
        return d["carCount"];
      });
      this.yScale = d3
        .scaleLinear()
        .range([this.height, 0])
        .domain(amountRange)
        .nice();
      this.yAxisGroup
        .attr("class", `y-axis-car-count-line-chart-${this.id}`)
        .call(d3.axisLeft(this.yScale).tickSize(-this.width + 120));
    },


    loadLineChart() {
      let self = this;
      let paths = this.accManagerPerformanceStatsChartContainer
        .selectAll(`.carCountByTimeline-${this.id}`)
        .data([this.AccManagerPerformanceStatsData], d => {
          return d["date"];
        });

        // console.log(this.AccManagerPerformanceStatsData);

      var pathMeta = paths
        .enter()
        .append("path")
        .attr("class", `carCountByTimeline-${this.id}`)
        .merge(paths)
        .attr(
          "d",
          d3
            .line()
            .x(d => {
              return self.xScale(new Date(d["date"]));
            })
            .y(d => {
              return self.yScale(d["carCount"]); 
            })
        )
        .attr("fill", "none")
        .attr("stroke", "red")
        .attr("stroke-width", 2);

        console.log(this.id);

        var transitionPath = d3
          .transition()
          .ease(d3.easeSin)
          .duration(2500);

        const pathLength = pathMeta.node().getTotalLength();

        pathMeta
          .attr("stroke-dashoffset", pathLength)
          .attr("stroke-dasharray", pathLength)
          .transition(transitionPath)
          .attr("stroke-dashoffset", 0);
      },

    removeCirclesBeforeUpdate() {
      d3.selectAll(`.carCountByTimeCircles-${this.id}`).remove();
    },

    loadCircles() {
      let self = this;
      let circles = this.accManagerPerformanceStatsChartContainer
        .selectAll(`.carCountByTimeCircles-${this.id}`)
        .data(this.AccManagerPerformanceStatsData, d => {
          return d["date"];
        });


        circles
          .enter()
          .append("circle")
          .attr("class", `carCountByTimeCircles-${this.id}`)
          .on("mouseover", onMouseOver)
          .on("mouseout", onMouseLeave)
          .merge(circles)
          .attr("cx", d => this.xScale(new Date(d["date"])))
          .attr("cy", d => this.yScale(d["carCount"]))
          .transition()
          .duration(1000)
          .attr("r", 3.5)
          .attr("fill", this.pointColor);

      
      function onMouseOver(d) {
        let xAxis = parseInt(d3.select(this).attr("cx")) - 70;
        let yAxis = parseInt(d3.select(this).attr("cy")) - 60;
        if (xAxis + 150 > self.width - 120) {
          yAxis = yAxis + 35;
          xAxis = xAxis - 90;
        }
        // console.log(self.accManager);
        self.accManagerPerformanceStatsChartContainer
          .append("foreignObject")
          .attr(
            "class",
            `accManagerByTooltipContainer-${self.id} accManagerByTimeTooltipContainer`
          )
          .attr("x", xAxis)
          .attr("y", yAxis)
          .attr("width", 200)
          .attr("height", 70)
          .html(function() {
            return `
              <div class="accManagerByTimeTooltip">
                      <span style="font-size:13px;color:grey">Date : ${
                        d["date"]
                      } </span>
                      <span style="font-size:13px;color:grey">Count : ${
                        d["carCount"]
                      } </span>
                  </div>
          `;
          });
      }

      function onMouseLeave() {
        d3.select(`.accManagerByTooltipContainer-${self.id}`).remove();
      }
    },

    renderChart() {
      this.loadYAxis();
      this.loadXAxis();
      this.loadLegend();
      if (this.width < 800) {
        d3.selectAll(`.x-axis-car-count-line-chart-${this.id} .tick text`).attr(
          "transform",
          "translate(-10, 50)rotate(-90)"
        );
      }
      this.removeCirclesBeforeUpdate();
      this.loadLineChart();
      setTimeout(() => {
        this.loadCircles();
      }, 2000);
    },

    getAccManagerPerformanceStats(mode) {
      let self = this;
      let url =
        process.env.VUE_APP_ANALYTICS_URL +
        "/api/v1/analytics/AccManagerPerformanceStats";
      let params = {
        startDate: this.startDate,
        endDate: this.endDate,
        carStatus:
          this.carStatus.length == 1
            ? this.carStatus[0]
            : this.carStatus.join(","),
        accManager:this.accManager,
      };
      getAxios(url, params)
        .then(function(res) {
          self.AccManagerPerformanceStatsData = res["data"]["data"][params.accManager];
          if (mode == "init") {
            self.initGraph();
            // console.log(self.AccManagerPerformanceStatsData)
          } else {
            self.renderChart();
          }
        })
        .catch(function(err) {
          console.log(err);
          self.showSnackBar("Error Occurred While Loading Chart!!");
        });
    },

    updateSVG() {
      let chart = document.getElementById(this.computedChartContainerId);
      let currentWidth = chart.clientWidth - 50;
      this.width = currentWidth;
      d3.select("#" + this.computedChartId).attr("width", currentWidth);
      this.renderChart();
      if (currentWidth < 800) {
        d3.selectAll(`.x-axis-car-count-line-chart-${this.id} .tick text`).attr(
          "transform",
          "translate(-10, 50)rotate(-90)"
        );
      } else {
        d3.selectAll(`.x-axis-car-count-line-chart-${this.id} .tick text`).attr(
          "transform",
          "translate(0, 0)rotate(0)"
        );
      }
    },

    resizeChart() {
      window.addEventListener("resize", this.updateSVG);
    },
  },
  beforeMount() {
    let today = new Date();
    let before5Months = new Date();
    before5Months.setMonth(before5Months.getMonth() - 3);
    this.endDate = today.toISOString().substr(0, 10);
    this.startDate = before5Months.toISOString().substr(0, 10);
    this.chartId = this.id;
  },
  mounted() {
    this.fetchAMList();
    this.getAccManagerPerformanceStats("init");
    this.resizeChart();
  },
  destroyed() {
    window.removeEventListener("resize", this.updateSVG);
  },
};
</script>
<style lang="scss">
@import "./AccManagerPerformanceStats.scss";
</style>
